import React from "react";
import InputField from "../components/fields/InputField";
import { useForm } from "react-hook-form";
import axios from "axios";

const AddPayment = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Handle form submission
  const onSubmit = async (e) => {
    // console.log(e)
    const response = await axios.post("https://site.ocpl.tech/api2/payment/upi", e);
    if (response.status === 200) {
      let upi_link = response.data?.upiLink
      window.open(
        // "upi://pay?pa=dipeshsharma938-2@okicici&pn=dipesh%20sharma&am=100&cu=INR&tn=Payment%20for%20services",
        upi_link,
        "_blank"
      );
    }
  };

  return (
    <div className="flex justify-center items-center flex-col w-full md:w-1/3 px-4 border border-solid border-gray-200 rounded-md p-3 shadow-lg shadow-gray-200">
      <div className="flex justify-center flex-col items-center w-full gap-y-5">
        <h2 className="bg-blue-600 rounded-md text-white text-center font-medium w-full leading-normal px-5 py-2">
          Add Payment to JaiGuru
        </h2>

        <div className="flex flex-col w-full gap-y-2 justify-start items-start">
          {/* Input Fields with Validation */}
          {/* <InputField
            name="username"
            title="Enter Your JaiGuru Login Username*"
            placeholder="Your Username"
            endMessage="Where can I find this?"
            control={control}
            // rules={{ required: "Username is required" }} // Add validation rules
          /> */}

          
<InputField
            name="email"
            title="Email*"
            placeholder="Your Email"
            endMessage="Email Address?"
            control={control}
            rules={{
              required: "Email is required",
            }}
          />

          <InputField
            name="amount"
            title="Enter Amount you want to add*"
            placeholder="Amount"
            endMessage="Minimum amount: INR 100/-"
            control={control}
            rules={{
              required: "Amount is required",
              min: { value: 100, message: "Minimum amount is INR 100" }
            }}
          />

          {/* <InputField
            name="contactNumber"
            title="Contact Number*"
            placeholder="Your Contact Number"
            endMessage={
              <div className="flex justify-center items-center gap-x-2">
                <input type="checkbox" />
                Remember me
              </div>
            }
            control={control}
            // rules={{
            //   required: "Contact number is required",
            //   pattern: {
            //     value: /^[0-9]{10}$/,
            //     message: "Invalid contact number format",
            //   },
            // }}
          /> */}

          <InputField
            name="note"
            title="Note*"
            placeholder="Note..."
            control={control}
          />

          {/* Submit Button */}
          <div className="flex justify-center items-center w-full flex-col gap-y-3 my-4">
            <button
              className="w-full bg-blue-400 rounded-md text-white py-2 px-3"
              onClick={handleSubmit(onSubmit)}
            >
              Add Money
            </button>
            <h2 className="leading-normal not-italic text-[14px] text-center font-medium text-blue-600">
              Contact Support
            </h2>
            <h2 className="leading-normal not-italic text-sm font-medium text-center text-gray-500">
              Powered By - PayTMe
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;
