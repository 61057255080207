import React from "react";
import { Controller } from "react-hook-form";

const InputField = ({
  control, // Control object from react-hook-form
  name = "",
  title = "",
  placeholder = "",
  endMessage = "",
  rules = {}, // Validation rules
}) => {
  return (
    <div className="flex flex-col justify-start w-full items-start gap-y-2 my-2">
      <label
        htmlFor={name}
        className="text-sm text-gray-500 font-medium leading-normal not-italic"
      >
        {title}
      </label>

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <input
              {...field}
              type="text"
              placeholder={placeholder}
              className={`outline-none p-2 border border-solid w-full rounded-md ${
                fieldState.error ? "border-red-500 bg-red-50" : "border-gray-200 bg-gray-100"
              }`}
            />
            {fieldState.error && (
              <p className="text-[13px] text-red-500 font-medium leading-normal not-italic">
                {fieldState.error.message}
              </p>
            )}
            {!fieldState.error && endMessage && (
              <p className="text-[13px] text-gray-500 font-medium leading-normal not-italic">
                {endMessage}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputField;
