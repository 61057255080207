import React, { useState } from 'react';
import axios from 'axios';

const AddBankAccount = ({ userId }) => {
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [upiId, setUpiId] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://site.ocpl.tech/api2/account/add', { userId, bankName, accountNumber, upiId });
            alert('Bank account added successfully!');
        } catch (error) {
            alert('Failed to add bank account: ' + error.response.data.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add Bank Account</h2>
            <input type="text" value={bankName} onChange={(e) => setBankName(e.target.value)} placeholder="Bank Name" required />
            <input type="text" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} placeholder="Account Number" required />
            <input type="text" value={upiId} onChange={(e) => setUpiId(e.target.value)} placeholder="UPI ID" required />
            <button type="submit">Add Account</button>
        </form>
    );
};

export default AddBankAccount;