import React, { useState } from 'react';
import axios from 'axios';

const Payment = ({ userId }) => {
    const [amount, setAmount] = useState('');

    const handlePayment = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/payment/upi', { userId, amount });
            window.location.href = response.data.upiLink;  // Redirect to UPI link
        } catch (error) {
            alert('Payment initiation failed: ' + error.response.data.message);
        }
    };

    return (
        <form onSubmit={handlePayment}>
            <h2>Initiate Payment</h2>
            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Amount" required />
            <button type="submit">Pay Now</button>
        </form>
    );
};

export default Payment;
