import React, { useState } from 'react';
   import Login from './components/Login';
   import Signup from './components/Signup';
   import AddBankAccount from './components/AddBankAccount';
   import Payment from './components/Payment';
import AddPayment from './pages/AddPayment';

   const App = () => {
     const [user, setUser] = useState(null);

     return (
       <div className='flex justify-center flex-col px-3 py-2 items-center w-full'>
       {/* <div > */}
         <h1>Pay-In Dashboard</h1>
         {!user ? (
           <>
           <div className='flex flex-col justify-start w-full items-start'>
           <Login setUser={setUser} />
           <Signup />
           </div>
           </>
         ) : (
           <>
           <div className='flex flex-col justify-start w-full items-start'>
           <AddBankAccount userId={user._id} />
             <Payment userId={user._id} />
            <AddPayment />
           </div>          
           </>
         )}
       </div>
     );
   };

   export default App;
   
